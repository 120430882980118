import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import Slider from "react-slick"
import ImageMeta from "../components/ImageMeta"

const casting = ({ location }) => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <SharedStateProvider>
      <Layout language={"en"}>
        <SEO
          title="2022 Smiling With the Stars program"
          description="Braces Omaha is hosting a casting call for our 2022 Smiling With the Stars program during National Children's Dental Health Month."
          pathname={location.pathname}
          lang={"en"}
        />

        <div className="cst-22">
          <div className="cst-22__hero">
            <img
              className="cst-22__hero--desktop-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/sws-2022-hero-image"
              alt="casting hero image"
            />
            <img
              className="cst-22__hero--mobile-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/sws-2022-mobile-hero-image"
              alt="casting hero image"
            />
            <div className="cst-22__hero-content">
              <img
                className="cst-22__hero--mobile"
                src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/sws-2022-logo"
                alt="smile with stars logo"
              />
              <h1>Creating Healthy, Picture-Perfect Smiles</h1>
            </div>
          </div>
          {/* 
          <div className="cst-22__close">
            <strong>
              <em>
                Thank you for your interest in our Smiling With the Stars
                program. The application period is now closed. Applicants will
                be notified once the selection process has been completed.
              </em>
            </strong>
          </div> */}

          <div className="cst-22__body">
            <div className="cst-22__content">
              <p>
                Braces Omaha hosted its Smiling With the Stars casting call
                during National Children's Dental Health Month and was proud to
                select its smile ambassador, Kyler. Kyler was surprised at our
                office with the news that he will receive braces free of charge.
              </p>
              <p>
                Kyler, 11, is in the sixth grade and attends Gretna Aspen Creek
                Middle School in Omaha. In his application and during his
                casting interview he said that he loved to smile but felt
                embarrassed to do so. As Braces Omaha’s newest star, Kyler will
                soon begin his journey to a straighter, brighter, and more
                confident smile.
              </p>

              <p>
                Dr. Clarke Stevens and his team look forward to helping Kyler.
                Be sure to follow Kyler’s story on{" "}
                <a href="https://www.facebook.com/bracesomaha/" target="_blank">
                  Facebook
                </a>{" "}
                and{" "}
                <a
                  href="https://www.instagram.com/braces_omaha/"
                  target="_blank">
                  Instagram
                </a>
                !
              </p>
            </div>
            <div className="cst-22__details">
              <p>Braces do more than straighten teeth. They also:</p>
              <ul>
                <li>Alleviate speech impairments</li>
                <li>Contribute to better oral hygiene</li>
                <li>Improve the ability to chew food</li>
                <li>Lessen the risk of injury from protruding teeth</li>
                <li>Decrease the risk of cavities and periodontal disease</li>
                <li>Reduce grinding and chipping of teeth</li>
              </ul>

              {/* <p className="cst-22__hightlight">
                We look forward to making our Smile Ambassador a star!
              </p>
              <p>The deadline to apply is March 10, 2022. </p> */}
            </div>
          </div>

          <div className="cst-22__slider">
            <Slider {...settings}>
              <ImageMeta
                publicId="BROM/DEV/IMG_0235"
                width="auto"
                className=""
                cloudname="nuvolum"
              />
              <ImageMeta
                publicId="BROM/DEV/IMG_0237"
                width="auto"
                className=""
                cloudname="nuvolum"
              />
              <ImageMeta
                publicId="BROM/DEV/IMG_0239"
                width="auto"
                className=""
                cloudname="nuvolum"
              />
            </Slider>
          </div>

          {/* <div className="cst-22__apply">Apply</div> */}

          {/* <div className="cst-22__form">
            <iframe
              class="seamlessdocsEmbededIframe"
              src="https://secureform.seamlessdocs.com/f/da4c0690f4026f93ad008ac7508d40d6?embedded=true"
              width="100%"
              height="3400px"
              frameborder="0"
              allowtransparency="true"
              sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
          </div> */}
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default casting
